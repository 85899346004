.thin-progress {
	border-radius: 0rem !important;
}

ul {
	padding-left: 20px;
}

.footer-border-top {
	border-top: 1px solid lightGray !important;
}

.standardTextPage {
	max-height: 100%;
	overflow-y: auto;
	scroll-behavior: smooth;
}

.iframe-docsign {
	height: 100%;
	width: 100%;
}

.standardPageCard {
	height: 100%;
	border-radius: 0;
}
