.small-header {
	height: 50px !important;
}

.text-page-title {
	display: flex;
	vertical-align: 'middle';
	flex-wrap: nowrap;
	align-content: space-around;
	justify-content: flex-start;
	align-items: center;
}
