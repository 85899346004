.container-loop-bike {
	overflow: hidden;
	width: 80%;
	height: auto;
}

.centerBike-loop-bike {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 26px;
	margin-left: -12px;
}

.center-loop-bike {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
}

#loop-loop-bike {
	height: 100px;
	width: 100px;
	border: #000000 solid 4px;
	border-radius: 200px;
}

#loop-loop-bike:before {
	background: linear-gradient(to left, #00000008 0%, #000000 30%, #000000 70%, #00000008 100%);
	content: '';
	display: block;
	height: 4px;
	left: -100px;
	position: relative;
	top: 94px;
	width: 300px;
}

#bike-wrapper-loop-bike {
	height: 108px;
	width: 108px;
	animation: drive-loop-bike 2s linear infinite;
}

#bike-loop-bike {
	height: 24px;
	width: 25px;
	background-image: url('https://iili.io/pQvRRe.png');
}

@keyframes drive-loop-bike {
	0% {
		margin-left: -364px;
		opacity: 0;
	}
	33.33% {
		transform: rotate(0deg);
		margin-left: -50px;
		opacity: 1;
	}
	66.66% {
		transform: rotate(-360deg);
		margin-left: -50px;
		opacity: 1;
	}
	100% {
		margin-left: 264px;
		transform: rotate(-360deg);
		opacity: 0;
	}
}
