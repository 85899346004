.todo-item {
	display: flex;
	align-items: flex-start; /* Changed alignment to flex-start */
	height: auto; /* Changed height to auto to allow for resizing */

	&:not(:last-child) {
		margin-bottom: $spacer;
	}

	.todo-bar {
		width: $todo-bar-width;
		height: 100%;
		margin-right: $spacer * 0.5;
	}

	

	.todo-content {
		flex: 1 0;
		margin-left: 7px;
		min-width: 0; /* Added min-width to allow content to shrink */

		.todo-title {
			display: block; /* Changed display to block to allow for multiline text */
			word-break: break-word; /* Added word-break property for line breaking */
		}

		.todo-subtitle {
			margin-top: $spacer * -0.25;
		}
	}

	.todo-extras {
		display: flex;
		flex: 0 0;
		flex-wrap: nowrap;
		align-items: center;
	}
}
