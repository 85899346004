.choice-container {
	width: 100%;
	border-radius: var(--bs-border-radius);
	background-color: transparent;
	border-width: 1px;
	border-style: solid;
	border-color: #dbdbdb;
	padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
