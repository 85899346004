.google-map-container {
	border-top-left-radius: var(--bs-border-radius);
	border-top-right-radius: var(--bs-border-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	margin-top: 0rem;
	margin-bottom: 0rem;
	height: 30vh;
	width: 100%;
}

.map-location-selector {
	height: 20vh;
	overflow-y: auto;
	margin-top: 0;
	padding-top: 0;
}

.map-master-container {
	border: 1px solid #dbdbdb;
	border-radius: var(--bs-border-radius);
	margin-top: 1rem;
}
