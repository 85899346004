.vehicle-progress-bar {
	border: var(--brand-color) 1px solid !important;
	border-radius: 20rem;
	margin-bottom: 1rem !important;
	width: 100%;
	max-width: 400px;
	min-width: 255px;
	background-color: var(--brand-color) !important; // transparent
}

.small-list-padding {
	padding-left: 2rem !important;
}

.progress-element {
	width: 34%;
	min-width: 57px;
	border-right: var(--brand-color) 1px solid;
	text-align: center;
	justify-content: center;
	font-weight: 400;
	height: 100%;
	background-color: white;
}

.progress-element-first {
	width: 33%;
	height: 100%;
	min-width: 40px;
	border-right: var(--brand-color) 1px solid;
	border-start-start-radius: inherit;
	border-end-start-radius: inherit;
	text-align: center;
	justify-content: center;
	font-weight: 400;
	background-color: white;
}

.progress-active {
	color: white;
	height: 100%;
	font-weight: 700;
	background-color: var(--brand-color) !important;
}

.progress-element-last {
	width: 33%;
	height: 100%;
	min-width: 40px;
	font-weight: 400;
	text-align: center;
	justify-content: center;
	border-end-end-radius: inherit;
	border-start-end-radius: inherit;
	background-color: white;
}

.progress-top {
	font-size: 0.8rem;
	text-align: center;
	height: 0.8rem;
	margin-left: 0.1rem;
	margin-right: 0.1rem;
	margin-bottom: 0.3rem;
}

.progress-bottom {
	font-size: 0.65rem;
	text-align: center;
	margin-left: 0.1rem;
	margin-right: 0.1rem;
	height: 0.7rem;
	margin-bottom: 0.1rem;
}
